<template>
  <Wrapper :appName="appName" :title="'Восстановление пароля'">
    <v-alert
      icon="mdi-shield-lock-outline"
      prominent
      text
      type="error"
      v-show="notValidated"
    >
      Аккаунт не найден, или автоматическое восстановление доступа для него
      невозможно.
    </v-alert>

    <v-alert outlined type="success" text v-if="isCompleted">
      Новый пароль отправлен на почту. Вы можете войти в аккаунт.
    </v-alert>

    <div class="auth__input mb-5 rounded">
      <input
        v-model="loginemail"
        name="loginemail"
        type="test"
        placeholder="E-mail или логин"
        :class="[step !== 1 ? 'auth__button_disabled_text' : '']"
        :disabled="step !== 1"
      />
    </div>

    <button
      class="auth__button auth__transition mt-5 rounded title font-weight-bold"
      v-on:click="pefrormRestore"
    >
      Далее
    </button>
  </Wrapper>
</template>

<script>
import { endpointUrl } from "../../app.config";
import axios from "axios";
import Wrapper from "./Wrapper.vue";

export default {
  components: {
    Wrapper,
  },
  props: {
    user: Object,
    appName: String,
  },
  name: "Restore",
  data: () => ({
    loginemail: "",
    step: 1,
    notValidated: false,
    isLoginInProcess: false,
    show1: false,
    show2: false,
    isCompleted: false,
  }),

  computed: {
  },

  created() {
  },

  methods: {
    goToLogin(e) {
      if (e) e.preventDefault();
      this.$router.push("/login");
    },
    pefrormRestore(e) {
      if (e) e.preventDefault();
      let self = this;

      if (self.step === 1 && !self.isLoginInProcess && self.loginemail) {
        self.notValidated = false;
        self.isLoginInProcess = true;
        axios({
          url: endpointUrl + "restoreByLoginOrEmail",
          method: "POST",
          data: {
            loginemail: self.loginemail,
          },
        }).then((res) => {
          self.isLoginInProcess = false;
          if (res && res.data && res.data.status === "ok") {
            self.step++;
            self.isCompleted = true;
          } else {
            self.notValidated = true;
          }
        });
      }

      if (self.step === 2) {
        this.$router.push("/login");
      }
    },
  },
};
</script>
